
















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";

const CostBearerDocumentModule = namespace("cost-bearer-document");

@Component({
  components: { AbortButton, FscSimpleCard, SaveButton },
})
export default class CostBearerDepositInvoiceDocumentPreview extends Vue {
  public name = "CostBearerDepositInvoiceDocumentPreview";

  @CostBearerDocumentModule.Action("generateCostBearerInvoicePreview")
  public generateInvoicePreview: any;

  @CostBearerDocumentModule.Getter("getMimeType")
  public mimeType: any;

  @CostBearerDocumentModule.Getter("getDataItem")
  public blob: any;

  @CostBearerDocumentModule.Getter("getIsLoading")
  public isLoading: any;

  @Prop()
  public studentEducationId!: any;

  @Prop()
  public invoiceData!: any;

  private source = "";

  public mounted(): void {
    if (this.studentEducationId) {
      const options = {
        id: this.studentEducationId,
        data: this.invoiceData,
      };
      this.generateInvoicePreview(options);
    }
  }

  @Watch("blob")
  public onStudentDocumentChange(blob: any) {
    if (blob) {
      this.source =
        "data:" +
        this.mimeType +
        ";base64," +
        //@ts-ignore
        new Buffer(blob, "binary").toString("base64");
    }
  }

  @Watch("studentEducationId")
  public onItemChange(id: any): void {
    if (id) {
      const options = {
        id: id,
        data: this.invoiceData,
      };
      this.generateInvoicePreview(options);
    }
  }

  protected ok(): void {
    this.$emit("ok");
  }

  protected cancel(): void {
    this.$emit("cancel");
  }
}
