










































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { v4 as uuidv4 } from "uuid";
import { namespace } from "vuex-class";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import CostBearerDepositInvoiceProducts from "@/views/CostBearers/CostBearerDepositInvoiceProducts.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import FscInputDecimal from "@/components/Field/FscInputDecimal.vue";
import CostBearerExistingProducts from "@/views/CostBearers/CostBearerExistingProducts.vue";
import CostBearerDepositInvoiceDocumentPreview from "@/views/CostBearers/CostBearerDepositInvoiceDocumentPreview.vue";
import { IMainTab } from "@/interfaces/IMainTab";
import CostBearerDepositInvoicePdfPreview from "@/views/CostBearers/CostBearerDepositInvoicePdfPreview.vue";

const DepositInvoiceModule = namespace("deposit-invoice");
const MainTabsModule = namespace("main-tabs");

@Component({
  components: {
    FscPageHeader,
    FscSimpleCard,
    AbortButton,
    CostBearerDepositInvoiceProducts,
    SaveButton,
    CostBearerExistingProducts,
    CostBearerDepositInvoiceDocumentPreview,
    FscInputDecimal,
    CostBearerDepositInvoicePdfPreview,
  },
})
export default class CostBearDepositInvoice extends Vue {
  public name = "CostBearDepositInvoice";

  @DepositInvoiceModule.Action("findOne")
  public findDepositInvoiceByEducationId: any;

  @DepositInvoiceModule.Getter("getDataItem")
  public depositInvoice: any;

  @DepositInvoiceModule.Getter("getDataItemLoading")
  public depositInvoiceLoading: any;

  @DepositInvoiceModule.Action("findAll")
  public findExistingByEducationId: any;

  @DepositInvoiceModule.Getter("getDataList")
  public existingInvoices: any;

  @DepositInvoiceModule.Getter("getIsLoading")
  public existingInvoicesLoading: any;

  @DepositInvoiceModule.Action("create")
  public createDepositInvoice: any;

  @DepositInvoiceModule.Getter("getSuccess")
  public success: any;

  @DepositInvoiceModule.Action("update")
  public updateDepositInvoice: any;

  @MainTabsModule.Getter("getActive")
  private activeTab!: IMainTab;

  public productsDto: any = {
    booked: [],
    notBooked: [],
  };

  public totalSum: any = 0;
  public bookedPrice = 0;
  public notBookedPrice = 0;
  public selectedExistingInvoiceId: any = null;
  public selectedExistingInvoiceRow: any = null;
  public selectedPdfInvoiceRow: any = null;

  protected costBearerInvoiceDocumentId = "cost-invoice-document-preview" + this.uuid;
  protected costBearerPdfPreviewId = "cost-invoice-pdf-preview" + this.uuid;

  public mounted() {
    this.findDepositInvoiceByEducationId({
      id: this.studentEducationId,
      resource: "cost-bearer-service-invoice/booked-and-planned-products",
    });
    this.findExistingByEducationId({
      resource: `cost-bearer-service-invoice/find-for-student-education/${this.studentEducationId}`,
    });
  }

  public get studentFirstName() {
    return this.$route.query.firstName ? this.$route.query.firstName : "";
  }

  public get studentLastName() {
    return this.$route.query.lastName ? this.$route.query.lastName : "";
  }

  public get licenseClass() {
    return this.$route.query.licenseClass ? this.$route.query.licenseClass : "";
  }

  public get studentEducationId() {
    return this.$route.query.educationId ? this.$route.query.educationId : "";
  }

  public generateNewAZR() {
    this.$bvModal.show(this.costBearerInvoiceDocumentId);
  }

  public get disableSubmitButton() {
    // if (this.productsDto.booked.length < 1 && this.productsDto.notBooked.length < 1) {
    //   return true;
    // } else if (this.selectedExistingInvoiceRow && this.selectedExistingInvoiceRow.paid) {
    //   return true;
    // }
    return false;
  }

  public selectBookedResources(resources: any) {
    this.productsDto.booked = [];
    this.productsDto.booked = [...resources];
    this.bookedPrice = 0;
    let calculatedPrice = 0;
    resources.map((resource: any) => {
      if (resource.price && resource.new) {
        calculatedPrice += resource.price;
      }
    });
    this.bookedPrice = calculatedPrice;
  }

  public selectNotBookedResources(resources: any) {
    this.productsDto.notBooked = [];
    this.productsDto.notBooked = [...resources];
    this.notBookedPrice = 0;
    let calculatedPrice = 0;
    resources.map((resource: any) => {
      if (resource.price && resource.new) {
        calculatedPrice += resource.price;
      }
    });
    this.notBookedPrice = calculatedPrice;
  }

  public onBookedExisting(invoices: any) {
    if (this.selectedExistingInvoiceId && this.productsDto.booked.length === 0) {
      this.productsDto.booked = [...invoices];
    }
  }

  public onNotBookedExisting(invoices: any) {
    if (this.selectedExistingInvoiceId && this.productsDto.notBooked.length === 0) {
      this.productsDto.notBooked = [...invoices];
    }
  }

  protected get calculatedPrice() {
    return this.bookedPrice + this.notBookedPrice;
  }

  protected get uuid(): any {
    return uuidv4();
  }

  protected cancelGenerateDepositInvoice() {
    this.$bvModal.hide(this.costBearerInvoiceDocumentId);
  }

  protected async submitGenerateDepositInvoice(): Promise<any> {
    if (this.selectedExistingInvoiceId) {
      await this.updateDepositInvoice({
        data: this.productsDto,
        resource: `cost-bearer-service-invoice`,
        id: this.selectedExistingInvoiceId,
      });
    } else {
      await this.createDepositInvoice({
        data: this.productsDto,
        resource: `cost-bearer-service-invoice/${this.studentEducationId}`,
      });
    }

    if (this.success) {
      this.$bvModal.hide(this.costBearerInvoiceDocumentId);
      this.$root.$emit("refresh-page", this.activeTab);
    }
  }

  public existingInvoiceRowClicked(id: any, row: any) {
    this.productsDto = {
      booked: [],
      notBooked: [],
    };
    this.selectedExistingInvoiceId = id;
    this.selectedExistingInvoiceRow = row;
  }

  public onPdfRowClicked(row: any) {
    this.$bvModal.show(this.costBearerPdfPreviewId);
    this.selectedPdfInvoiceRow = row;
  }

  @Watch("studentEducationId")
  public onStudentEducationIdWatch(id: any) {
    if (id) {
      this.findDepositInvoiceByEducationId({
        id: id,
        resource: "cost-bearer-service-invoice/booked-and-planned-products",
      });
      this.findExistingByEducationId({
        resource: `cost-bearer-service-invoice/find-for-student-education/${this.studentEducationId}`,
      });
    }
  }

  @Watch("existingInvoices", { deep: true, immediate: true })
  public onExistingProductsWatch(products: any) {
    if (products) {
      this.totalSum = 0;
      let calculatedPrice = 0;
      products.map((product: any) => {
        if (product.amountInEur) {
          calculatedPrice += product.amountInEur;
        }
      });
      this.totalSum = Math.abs(parseInt(calculatedPrice.toFixed(2)));
    }
  }
}
